<template>
  <tr>
    <td style="width: 270px;" :style="car.sold ? 'background: rgb(40 167 69 / 25%);' : ''">
      <div class="panel panel-default mb-0"
           style="border-bottom: none;"
           :style="car.sell_reject ? 'background: rgb(220 53 69 / 25%);' : ''">
        <div class="panel-heading">
          <router-link :to="{ name: 'car.details', params: { carId: car.id }}">
            <h5 class="car-title font-scale-2 mt-0 mb-0" style="max-width: 270px;">
              #{{ car.id }}
              <strong class="margin-l-5">
                {{ car.title }}
              </strong>
            </h5>
          </router-link>
        </div>
        <div class="panel-body">
          <div class="row">
            <div class="col-12 text-center">
              <img v-if="car.main_photo"
                   :src="car.main_photo"
                   class="img-fluid"
                   style="max-width: 270px;"
                   alt="">
            </div>
            <div class="col-12">
              <ul class="list-group list-group-unbordered font-small" style="letter-spacing: -.035rem;">
                <li class="list-group-item pb-1">
                  <strong>{{ car.branch.title }}</strong>
                  / {{ car.specification.fuel.title }}
                  / {{ car.specification.gearbox.title }}
                  / {{ car.specification.race }} т. км.
                  / <strong class="text-black font-small">{{ car.year }}</strong> г.
                </li>
                <li class="list-group-item pt-1 pb-1">
                  <span v-if="car.contract_type === 'free'" class="label label-info font-medium mt-1">
                    Договор: бесплатно
                  </span>
<!--                  <span v-if="car.contract_type === 'fee'" class="label label-info font-medium mt-1">-->
<!--                    Договор: абонплата-->
<!--                  </span>-->
                  <span v-if="car.contract_type === 'compensation'" class="label label-info font-medium mt-1">
                    Договор: компенсация
                  </span>
                  <span class="label label-front font-medium pull-right">{{ car.credit_percent }}%</span>
                </li>
                <li v-if="car.owner" class="list-group-item pt-1 pb-1">
                  <router-link :to="{ name: 'client.details', params: { clientId: car.owner.id }}">
                    <i class="fas fa-user-circle"></i>
                    <strong class="text-black">
                      {{ car.owner.name }}
                    </strong>
                  </router-link>
                  <br />
                  <span class="text-muted font-medium font-small">
                    <i class="fa fa-phone-square text-blue" />
                    <a :href="'tel:+' + car.owner.phone">
                      {{ car.owner.phone }}
                    </a>
                  </span>
                  <br />

                  <template v-if="car.selling && car.selling.length">
                    <a @click="callLogging()"
                       :href="'tel:+' + car.owner.phone"
                       class="pull-right pointer" style="margin-top: -40px;">
                      <i v-if="car.selling.at(-1).comment" class="fa fa-phone-square status-phone"
                         style="color: green" />
                      <span v-else="!car.selling.at(-1).comment">
                          <i class="fa fa-phone-square status-phone" style="color:red" />
                        </span>
                    </a>
                  </template>
                </li>
                <li v-if="car.selling.length" class="list-group-item pt-1 pb-1">
                  В продаже <strong>{{ car.selling.at(-1).month_count }}</strong> мес.
                  <span style="float: right;">
                    <strong>{{ car.selling.at(-1).call_count }}</strong>й звонок
                  </span>
                  <br />
                  Добавлен <span class="font-medium">{{ car.created_date }}</span>
                </li>
                <li class="list-group-item pt-1 pb-1">
                  <i class="fas fa-user-tie"></i>
                  <strong class="text-black">
                    {{ car.creator.name }}
                  </strong>

                  <span style="float: right;">
                    <strong class="font-scale-2">{{ car.price }} $</strong>
                    <small v-if="car.old_price && car.is_top_price"
                           class="font-medium text-red text-muted margin-l-5 font-small">
                      {{ car.old_price }}$
                    </small>
                  </span>
                </li>
                <li v-if="car.price_for_owner" class="list-group-item pt-1 pb-1">
                  <strong class="text-black">
                    Стоимость в руки
                  </strong>

                  <span style="float: right;">
                    <strong class="font-scale">{{ car.price_for_owner }} $</strong>
                  </span>
                </li>
                <li v-if="car.contract" class="list-group-item font-scale">
                  <strong>Договор компенсации</strong>

                  <br />
                  <span class="font-small">
                        Создан <strong>{{ car.contract.created_date }}</strong>
                      </span>
                  <br />
                  <span class="font-small">
                        Оплачен до
                        <strong :class="car.contract.is_expired ? 'text-red' : 'text-green'">
                          {{ car.contract.paid_date }}
                        </strong>
                      </span>
                </li>
                <li class="list-group-item no-border pt-1 pb-1">
                  <strong class="text-black">
                    До следующего звонка
                  </strong>

                  <span style="float: right;">
                    <strong class="font-scale">{{ car.next_call_time }}</strong>
                  </span>
                </li>
                <li v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                    class="list-group-item no-border pt-0 pb-1">
                  <hr class="mt-1 mb-2" />
                  <div class="form-group">
                    <input class="pointer"
                           id="is_market_price"
                           style="transform: scale(1.2);"
                           type="checkbox"
                           v-on:change="toggleMarketPrice(car)"
                           v-model="car.is_market_price"
                           :checked="car.is_market_price === true">
                    <label for="is_market_price" class="font-medium margin-l-5 padding-l-10">
                      Рыночная стоимость
                    </label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </td>
    <td :style="(car.owner_type === 'company' ? 'background: #EBEBEE; ' : '') + 'width: auto;'">
      <h5 v-if="car.owner_type === 'company'" class="text-center text-muted mt-4">Авто компании</h5>
      <div class="toast show font-scale" v-if="car.selling" v-for="selling_item in car.selling">
        <div class="toast-header font-scale-2">
          <strong class="text-black">{{ selling_item.call_count }}</strong>й звонок
        </div>
        <div class="toast-body">
          <ul class="list-group list-group-unbordered" style="letter-spacing: -.035rem;">
            <li v-if="selling_item.remark" class="list-group-item font-small text-muted pt-0 pb-2">
              <strong>Примечание: </strong> {{ selling_item.remark }}
            </li>
            <li class="list-group-item pt-0 pb-1" style="word-break: break-all;">
              <div v-if="selling_item.comment && selling_item.last_editor_id">
                <i class="fas fa-comment-dots text-muted font-small"></i>
                {{ selling_item.comment }}
              </div>
              <div v-else class="text-center font-medium font-scale">
                Нет данных
              </div>
            </li>
            <li class="list-group-item font-medium font-scale-1 pt-1 pb-0">
              {{ selling_item.current_price }}$
            </li>
            <li v-if="selling_item.editor" class="list-group-item text-right pt-1 pb-0">
              <small class="text-muted">
                Обновил {{ selling_item.editor.name }}, <strong>{{ selling_item.updated_date }}</strong>
              </small>
            </li>
          </ul>
        </div>
        <div v-if="
               [1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
               $store.getters['auth/authUser'].id === car.creator.id
             "
             class="toast-header" style="border-bottom: 0;">
          <button class="btn btn-sm"
                  :class="(selling_item.comment && selling_item.last_editor_id) ? 'btn-primary' : 'btn-success'"
                  title="Редактировать"
                  type="button"
                  v-on:click="showEditCarSellingModal(selling_item)">
            <i class="fa"
               :class="(selling_item.comment && selling_item.last_editor_id ? 'fa-edit' : 'fa-plus')" />
          </button>

          <a v-if="car.owner" :href="'tel:+' + car.owner.phone" class="btn btn-sm">
            <span>
              <i class="fa fa-phone-square status-phone text-primary" />
            </span>
          </a>

          <button class="btn btn-default"
                  title="Информация"
                  type="button"
                  v-on:click="showSellingScriptModal(selling_item)">
            <i class="fa fa-info" />
          </button>
        </div>

        <modal :adaptive="true"
               :clickToClose="true"
               :draggable="false"
               :name="'edit_car_selling_modal_' + selling_item.id"
               :resizable="false"
               :scrollable="false"
               :shiftX="0.5"
               :shiftY="0.5"
               :height="'auto'"
               overlayTransition="fade"
               transition="fade">
          <div class="box box-primary box-modal mb-0">
            <div class="box-header with-border mb-3">
              <h4>
                Звонок #{{ selling_item.call_count }}: {{ car.title }}
              </h4>
            </div>

            <div class="box-body">
              <form @keydown="form.onKeydown($event)" @submit.prevent="editCarSelling">
                <div class="row">
                  <div class="col-12">
                    <template v-if="[1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)">
                      <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('remark') }">
                              <textarea class="form-control"
                                        maxlength="255"
                                        id="remark"
                                        rows="2"
                                        placeholder="Примечание к звонку"
                                        v-model="form.remark">
                              </textarea>
                        <HasError :form="form" field="remark" />
                      </div>
                      <hr />
                    </template>

                    <template
                      v-if="[1, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) && !car.is_market_price">
                      <div class="form-group">
                        <input class="pointer"
                               id="is_required_price_change"
                               style="transform: scale(1.2);"
                               type="checkbox"
                               v-model="form.is_required_price_change">
                        <label for="is_required_price_change" class="text-muted margin-l-5 padding-l-10">
                          Обязательное изменение стоимости
                        </label>
                        <HasError :form="form" field="sum" />
                      </div>
                      <hr />
                    </template>

                    <div class="form-group">
                      <label for="price_from">
                        Текущая стоимость
                      </label>
                      <input :max="999999999"
                             :min="1"
                             class="form-control"
                             name="price_from"
                             placeholder="Текущая цена авто"
                             title="Текущая цена авто"
                             type="number"
                             v-model="form.current_price">
                      <HasError :form="form" field="details.price_from" />
                      <small
                        v-if="form.is_required_price_change && car.price === form.current_price && !car.is_market_price"
                        class="font-medium font-small text-danger">
                        Необходимо изменить цену авто
                      </small>
                    </div>

                    <div class="form-group">
                      <label for="price_for_owner">
                        Стоимость в руки
                      </label>
                      <input :max="999999999"
                             class="form-control"
                             name="price_for_owner"
                             placeholder="Стоимость в руки"
                             title="Стоимость в руки"
                             type="number"
                             v-model="form.price_for_owner">
                      <HasError :form="form" field="details.price_for_owner" />
                    </div>

                    <div class="form-group" v-bind:class="{ 'has-error': form.errors.has('comment') }">
                              <textarea class="form-control"
                                        maxlength="255"
                                        id="comment"
                                        rows="3"
                                        placeholder="Комментарий"
                                        v-model="form.comment">
                              </textarea>
                      <HasError :form="form" field="comment" />
                    </div>

                    <AlertErrors :form="form" message="Ошибки валидации. Проверьте введённые данные." />

                    <Button :disabled="!validateData || form.busy"
                            :form="form"
                            :loading="form.busy"
                            class="btn btn-lg btn-success pull-right"
                            style="min-width: 100px;">
                      <i class="fa fa-save"></i>
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </modal>

        <modal :adaptive="true"
               :clickToClose="true"
               :draggable="false"
               :name="'selling_script_modal_' + selling_item.id"
               :resizable="false"
               :scrollable="false"
               :shiftX="0.5"
               :shiftY="0.5"
               :height="'auto'"
               overlayTransition="fade"
               transition="fade">
          <div class="box box-primary box-modal mb-0">
            <div class="box-header with-border mb-3">
              <h4>
                Скрипт звонка #{{ selling_item.call_count }}
              </h4>
            </div>

            <div class="box-body">
              <p v-html="formatScript(scripts[selling_item.call_count])"></p>
            </div>
          </div>
        </modal>
      </div>
    </td>
    <td class="text-center" style="width: 10%">
      <template v-if="!car.sold">
        <button v-if="
                  [1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                  $store.getters['auth/authUser'].id === car.creator.id
                "
                class="btn btn-primary font-medium mt-3 mb-1"
                title="Указать продажу"
                type="button"
                v-on:click="showCarSoldModal(car.id)">
          Указать продажу
        </button>

        <car-sold-modal :car="car" />

        <button v-if="
                  [1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                  $store.getters['auth/authUser'].id === car.creator.id
                "
                class="btn btn-info font-medium mt-1 mb-2"
                title="Добавить залог"
                type="button"
                v-on:click="showCarDepositModal(car.id)">
          Добавить залог
        </button>

        <car-deposit-modal :car="car" />
      </template>
      <template v-else>
        <div class="text-center mt-2 mb-2">
          <label class="label label-success font-scale-3 font-medium">
            Продано
          </label>
          <br />
          <span class="font-medium font-small">
            {{ car.sold.created_date }}
          </span>
        </div>

        <hr class="my-1" />

        <ul class="list-group list-group-unbordered font-small text-left px-1"
            style="letter-spacing: -.050rem;">
          <li class="list-group-item pt-1 pb-1">
            <router-link :to="{ name: 'client.details', params: { clientId: car.sold.client.id }}">
              <i class="fas fa-user-circle"></i>
              <strong class="text-black">
                {{ car.sold.client.name }}
              </strong>
            </router-link>
            <br />
            <span class="text-muted font-medium font-small">
              <i class="fa fa-phone-square text-blue" />
              <a :href="'tel:+' + car.sold.client.phone">
                {{ car.sold.client.phone }}
              </a>
            </span>
          </li>
          <li class="list-group-item no-border pt-1 pb-1">
            Сумма:
            <span style="float: right;">
              <strong class="font-scale">{{ car.sold.sum }} $</strong>
            </span>
          </li>
          <li class="list-group-item no-border pt-0 pb-1">
            Владельцу:
            <span style="float: right;">
              <strong class="font-scale">{{ car.sold.owner_sum }} $</strong>
            </span>
          </li>
          <li class="list-group-item no-border pt-0 pb-1">
            Комиссия:
            <span style="float: right;">
              <strong class="font-scale">{{ car.sold.sum - car.sold.owner_sum }} $</strong>
            </span>
          </li>
          <li v-if="car.sold.comission_sum > 0" class="list-group-item no-border pt-0 pb-1">
            Комиссия:
            <span style="float: right;">
              <strong class="font-scale">{{ car.sold.comission_sum }} $</strong>
            </span>
          </li>
          <li v-if="car.sold.sold_for_crypto" class="list-group-item no-border pt-0 pb-1">
            <strong>
              Продано за криптовалюту
            </strong>
          </li>
          <li v-if="car.sold.comment" class="list-group-item no-border pt-0 pb-1">
            <small>
              {{ car.sold.comment }}
              <br />
              <span class="font-medium">{{ car.sold.created_date }}</span>
            </small>
          </li>
        </ul>

        <button v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                class="btn btn-danger btn-sm font-medium mt-2"
                title="Удалить авто"
                type="button"
                v-on:click="removeCar(car.id)">
          Удалить авто
        </button>

        <button v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                class="btn btn-default btn-sm font-medium mt-2 mb-1"
                title="Отменить продажу"
                type="button"
                v-on:click="deleteSoldCar()">
          Отменить продажу
        </button>
      </template>

      <template v-if="car.deposits_count > 0">
        <hr class="my-2" />
        <div class="text-center mt-3 mb-0">
          <label class="label label-success font-scale-1 font-medium">
            Внесен залог
          </label>
          <span class="font-medium font-small">
            {{ car.deposits.at(-1).created_date }}
          </span>
        </div>

        <ul class="list-group list-group-unbordered text-left px-1"
            style="letter-spacing: -.050rem;">
          <li class="list-group-item no-border py-0">
            Сумма:
            <span style="float: right;">
              <strong class="font-scale">{{ car.deposits.at(-1).sum }} $</strong>
            </span>
          </li>
          <li v-if="car.deposits.at(-1).comment" class="list-group-item no-border pt-0 pb-1">
            <small>
              {{ car.deposits.at(-1).comment }}
              <br />
              <span class="font-medium">{{ car.deposits.at(-1).created_date }}</span>
            </small>
          </li>
        </ul>

        <router-link :to="{ name: 'car.details', params: { carId: car.id, currentTab: 'Задатки' }}"
                     class="btn btn-default btn-sm mt-2"
                     title="Все залоги"
                     type="button">
          Все залоги ({{ car.deposits_count }})
        </router-link>
      </template>
    </td>
    <td class="text-center" style="width: 10%">
      <template v-if="!car.sold">
        <template v-if="!car.sell_reject">
          <button v-if="
                    [1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                    $store.getters['auth/authUser'].id === car.creator.id
                  "
                  class="btn btn-warning font-medium mt-3 mb-1"
                  title="Снять с продажи"
                  type="button"
                  v-on:click="showCarSellRejectModal(car.id)">
            Снять с продажи
          </button>

          <car-sell-reject-modal :car="car" />
        </template>
        <template v-else>
          <div class="text-center mt-3 mb-3">
            <label class="label label-danger font-scale font-medium">
              Снято с продажи
            </label>
          </div>

          <button v-if="
                    [1, 2, 6, 7, 8, 9].includes($store.getters['auth/authUser'].role.id) ||
                    $store.getters['auth/authUser'].id === car.creator.id
                  "
                  class="btn btn-default btn-sm font-medium mt-1 mb-1"
                  title="Вернуть в продажу"
                  type="button"
                  v-on:click="cancelCarSellRejectModal(car.id)">
            Вернуть в продажу
          </button>

          <hr class="my-1" />

          <ul class="list-group list-group-unbordered font-small text-left px-1"
              style="letter-spacing: -.050rem;">
            <li class="list-group-item pt-1 pb-1">
              <small>
                {{ car.sell_reject.comment }}
              </small>
            </li>
            <li class="list-group-item no-border pt-2 pb-1 text-right">
              <i class="fas fa-user-tie"></i>
              <strong class="text-black">
                {{ car.sell_reject.creator ? car.sell_reject.creator.name : '-' }}
              </strong>
            </li>
          </ul>

          <button v-if="[1, 2, 7, 8, 9].includes($store.getters['auth/authUser'].role.id)"
                  class="btn btn-danger btn-sm font-medium mt-2"
                  title="Удалить авто"
                  type="button"
                  v-on:click="removeCar(car.id)">
            Удалить авто
          </button>
        </template>
      </template>
    </td>
  </tr>
</template>

<script>
import Vue from "vue";
import VModal from "vue-js-modal";
import Form from "vform";
import VuejsDialog from "vuejs-dialog";

Vue.use(VModal);
Vue.use(VuejsDialog);

Form.axios = API.apiClient;

Vue.component("car-sold-modal", (resolve) => {
  return require(["./sold-modal"], resolve);
});
Vue.component("car-deposit-modal", (resolve) => {
  return require(["./deposit-modal"], resolve);
});
Vue.component("car-sell-reject-modal", (resolve) => {
  return require(["./../sell-reject-modal"], resolve);
});

export default {
  name: "car-selling-item",

  components: {
    Button, HasError, AlertError, AlertErrors,
  },

  props: {
    car: {
      type: Object,
      default() {
        return this.$props.$_PaginationObject;
      },
    },
  },

  data: () => ({
    form: new Form({
      remark: null,
      comment: null,
      current_price: null,
      price_for_owner: null,
      is_required_price_change: null,
    }),
    scripts: {
      1: "1-й звонок владельцу знакомство с клиентом, рассказываем стратегию продажи авто : \n" +
        "Задача :  \n" +
        "\n" +
        "Первый звонок - это выстраивание отношений с клиентом . В нём нужно максимально расположить к себе и компании. \n" +
        "\n" +
        "Добрый день, меня зовут [ИМЯ].  Я Ваш личный менеджер по продаже авто в компании Автокредо. \n" +
        "Ваша машина опубликована на всех ресурсах  и поднята в топ 4 . За 4 года  опыта работы  с автомобилями  я могу предположить,  что будет вот такая активность ( говорим цифры и приводим факты по продажам других авто ) . \n" +
        "Следующий раз Вас наберу через 3 дня  и обсудим дальнейшею стратегию продажи !  Вам хорошего дня и до связи! \n" +
        "\n",
      2: "2-й звонок владельцу предоставить статистику за три  для клиента : \n" +
        "\n" +
        "Задачи по звонку: \n" +
        "Мы должны показать клиенту , что по его машине ведётся  активная работа . \n" +
        "\n" +
        "Примерный скрипт: \n" +
        "\n" +
        "Добрый день! Компания Автокредо, [ИМЯ]. \n" +
        "Смотрите ,  за 3 дня была довольно таки слабая активность.  \n" +
        " (  говорим цифры )  Сейчас закончилась реклама  мы были в топ 4 и сейчас плодняли в топ 2 .Ещё авто  предложили  нашим партнерам , которые занимаются подбором авто в Харькове , Днепр, Полтава ,Киев. \n" +
        "Я предлагаю ещё посмотреть до конца недели по активности и после уже будем принимать решение касаемо цены. \n" +
        "\n" +
        "По авто нет движение вообще  . Закончился топ 4 и сейчас поставили в топ2 на 4 дня . Я сейчас прозвоню подборщикам в Харькове , Полтаве , Днепре. \n",
      3: "3-й звонок владельцу и предоставить  итоги недели . ( звонок через 7 дней когда поступила машина ) \n" +
        "\n" +
        "Задача: \n" +
        "\n" +
        "Это один из важных звонков. В этом диалоге важно понимать настроение продавца  и не передавить в торгах. \n" +
        "\n" +
        "Добры день! [ИМЯ], Автокредо. \n" +
        "Прошла неделя . Статистика не очень, ( мы должны сказать чётко по цифрам которые указаны в АВТОРИА) \n" +
        "Обычно у нас , 700 просмотров , 30 избранных , 5  сохранённых телефонов и это с тем учётом , что мы добавили в ТОП 4 и пока просмотров не много. \n" +
        "По подборщикам пока тишина.  Предложили 4-м партнёрам. Пока думают. \n" +
        "\n" +
        "\n" +
        "Что я предлагаю ?могу ещё более детально провести анализ рынка и сказать своё виденье касаемо стоимости авто. ( здесь задача  сделать видео обзор рынка авто и сделать вывод) \n" +
        "\n" +
        "У нас есть 2 варианта : \n" +
        "\n" +
        "1-й  пригласить клиента в офис и провести лично  анализ рынка. \n" +
        "2-й выслать видео анализ  записи экрана  и отправить в мессенджер. \n" +
        "\n",
      4: "4-й звонок задача сделать цену на основании предыдущих звонков ( звонок 14 дней после поступление ) \n" +
        "\n" +
        "Добрый день! [ИМЯ] , ваш личный менеджер по продаже авто. \n" +
        "Хочу поделиться с Вами итогами  по динамике. \n" +
        "( показываем динамику , рассказываем показатели какие  были , сколько было тест драйвов) \n" +
        "\n" +
        "После этого мы делаем цену . Наша задача поставить машину в рынок , чтобы было больше активности. \n",
      5: "5-й  звонок предоставить изменений после изменения цены ( через 21 день после поступления )  \n" +
        "\n" +
        "Добрый день! [ИМЯ] , Автокредо. \n" +
        "Мы говорим отчёт о проделанной работе : \n" +
        "1. Статистика с авториа ( просмотры , \n" +
        "2. Было  3 просмотра авто ( если авто в салоне)  или было всего 3 звонка входящих. \n" +
        "3. Предложили нашим парнерам лизинговой компани . 1  человек интересовался и сейчас ждём согласование. \n" +
        "Держу в курсе и мы заинтересованы в максимально быстрой продаже.\n" +
        "\n",
      6: "6-й звонок  итоги месяца по активности , просмотрам , звонкам. \n" +
        "\n" +
        "Задача: \n" +
        "Уговорить клиента оставить оставить в салоне. \n" +
        "Проблема : люди не хотят оставить машину и мы можем предложить отвезти клиента домой. \n" +
        "\n" +
        "Скрипт: \n" +
        "\n" +
        "Добрый день! \n" +
        "За 28 дней активность по авто не очень . \n" +
        "Предоставляем статистику с авториа. Сколько было просмотров , сколько было  тест драйвов. Был запрос на кредит , но человеку не одобрили. \n" +
        "\n" +
        "Я с автомобилями работаю более 5-лет и Ваше авто мы продавали неоднократно. Что я предлагаю. \n" +
        "\n" +
        "Делаем оценку авто : \n" +
        "- комплектация ;\n" +
        "- привод;\n" +
        "-техническое состояние ; \n" +
        "- состояние ЛКП;\n" +
        "- устранение недостатков по авто ( месяц прошёл , людям не нравится этот недостаток и они не хотят покупать авто ) \n" +
        "- перспективы  продаж на неделю . \n",
    }
  }),

  async created() {
    try {
      console.log(this.scripts);
    } catch (error) {
      errorHandler(error);
    }
  },

  computed: {
    validateData: function() {
      return this.form.current_price;
    },
  },

  methods: {
    async editCarSelling() {
      await this.form.put("/cars/selling").then(() => {
        showSuccess();
        this.EventBus.$emit("cars-selling-items-reload");
        this.$modal.hide("edit_car_selling_modal_" + this.form.id);
      }).catch((error) => {
        errorHandler(error);
      });
    },

    async showEditCarSellingModal(carSellingItem) {
      this.form.id = carSellingItem.id;
      this.form.car_id = carSellingItem.car_id;
      this.form.remark = carSellingItem.remark;
      this.form.comment = carSellingItem.comment;
      this.form.current_price = carSellingItem.current_price;
      this.form.price_for_owner = this.car.price_for_owner;
      this.form.is_sold = carSellingItem.is_sold;
      this.form.is_reject = carSellingItem.is_reject;
      this.form.is_required_price_change = carSellingItem.is_required_price_change;

      this.$modal.show("edit_car_selling_modal_" + carSellingItem.id);
    },

    async showSellingScriptModal(carSellingItem) {
      this.$modal.show("selling_script_modal_" + carSellingItem.id);
    },

    async showCarSoldModal(carId) {
      this.$modal.show("car_sold_modal_" + carId);
    },

    async showCarDepositModal(carId) {
      this.$modal.show("car_deposit_modal_" + carId);
    },

    async showCarSellRejectModal(carId) {
      this.$modal.show("car_sell_reject_modal_" + carId);
    },

    toggleMarketPrice(car) {
      API.apiClient.put("/car/" + car.id + "/toggle_market_price").then(() => {
        showSuccess();
      }).catch((error) => {
        errorHandler(error);
      });
    },

    removeCar(carId) {
      this.$dialog.confirm("Вы уверены что хотите удалить запись?", this.$dialogOptions).then(() => {
        API.apiClient.delete("/cars/" + carId).then(() => {
          this.EventBus.$emit("card-filter-changed");
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },

    cancelCarSellRejectModal(carId) {
      this.$dialog.confirm("Вы уверены что хотите восстановить авто в продаже?", this.$dialogOptions).then(() => {
        API.apiClient.post("/car/" + carId + "/sell_reject/cancel").then(() => {
          window.location.reload();
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },

    deleteSoldCar() {
      this.$dialog.confirm("Вы уверены что хотите удалить продажу авто?", this.$dialogOptions).then(() => {
        API.apiClient.delete("/car/" + this.car.id + "/sold").then(() => {
          this.EventBus.$emit("card-filter-changed");
          showSuccess();
        }).catch((error) => {
          errorHandler(error);
        });
      });
    },

    callLogging() {
      API.apiClient.put("/tasks/add_call/" + this.task_id).then(() => {
      }).catch((error) => {
        errorHandler(error);
      });
    },

    formatScript(script) {
      if (script && script !== 'undefined') {
        return script.replace(/\n/g, '<br>');
      }
    }
  },
};
</script>

<style scoped>
.table > tbody > tr > td {
  border: 1px solid #e3e3e5;
}

tr > td {
  padding: 10px;
}

tr:hover {
  background: unset;
}

td:hover {
  background: unset;
}

th {
  border-bottom: 0 !important;
}

tr > td {
  padding: 0 !important;
}

.panel-heading {
  padding: 8px;
}

.panel-body {
  padding: 8px;
}

.btn, a {
  white-space: break-spaces;
}

.toast {
  display: inline-grid;
  border-radius: 0;
  width: 220px;
  box-shadow: none;
}

.toast-body {
  padding: 8px 8px 10px 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.toast-header {
  border-radius: 0;
  padding: 8px;
}

.toast-header:hover {
  background: #f4f4f6;
}

.panel {
  background: unset;
}

.list-group-item {
  background: unset;
}

.status-phone {
  font-size: 36px;
}
</style>
